<template>
  <div v-append="payHtml">
  </div>
</template>

<script>
export default {
  data() {
    return {
      payHtml: ''
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.pay(newValue.job_id)
      }
    }
  },
  methods: {
    pay(job_id) {
      this.$api.PAY_ALITRADE_FINAL({job_id:job_id}).then(res => {
        this.payHtml = res
      })
    }
  },
}
</script>